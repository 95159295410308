import RestApi, { agriMarketingServiceBaseUrl } from '../config/api_config'

export default {
  computed: {
    hasDropdownLoadedAgriMarketing () {
      return this.$store.state.agriMarketing.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedAgriMarketing: function (newValue) {
      if (!newValue) {
        this.loadDropdownCommonConfig()
        this.loadDropdownDam()
      }
    }
  },
  created () {
    const hasDropdownLoadedAgriMarketing = this.$store.state.agriMarketing.hasDropdownLoaded
    if (!hasDropdownLoadedAgriMarketing || window.performance) {
    }
    this.loadDropdownCommonConfig()
  },
  methods: {
    async loadDropdownCommonConfig () {
      const response = await RestApi.getData(agriMarketingServiceBaseUrl, 'common-dropdowns', null)
      if (response.success) {
        let marketList = response.data.marketList
        const activeRoleId = this.$store.state.Auth.activeRoleId
        if (activeRoleId !== 1) {
          const responseData = await RestApi.getData(agriMarketingServiceBaseUrl, '/crop-price-info/config/user-privilege/details/' + this.$store.state.Auth.authUser.user_id, null)
          if (responseData.success) {
            marketList = this.getMarketList(marketList, responseData.data.market_id)
            this.storeMainFn(response, marketList, responseData.data)
          } else {
            this.storeMainFn(response, [], [])
          }
        } else {
          this.storeMainFn(response, marketList, [])
        }
      }
    },
    storeMainFn (response, marketList, loggedUser) {
      this.$store.dispatch('agriMarketing/mutateAgriMarketingProperties', {
        hasDropdownLoaded: true,
        loggedUserPrivilege: loggedUser,
        regionList: response.data.regionsList,
        zoneList: response.data.zonesList,
        unitList: response.data.unitList,
        hatList: response.data.hatsList,
        seasonList: response.data.seasonsList,
        cottonVaritiesList: response.data.cottonVaritiesList,
        cottonNameList: response.data.cottonNamesList,
        commodityGroupList: response.data.commodityGroupList,
        commoditySubGroupList: response.data.commoditySubGroupList,
        commodityNameList: response.data.commodityNameList,
        campaignNameList: response.data.campaignNameList,
        divisionalOfficeList: response.data.divisionalOfficeList,
        commodityTypeList: response.data.commodityTypeList,
        marketList: marketList,
        alertPercentageList: response.data.alertPercentageList,
        ginnerGrowerList: response.data.ginnerGrowerList,
        leaseYearList: response.data.leaseYearList,
        measurementUnitList: response.data.measurementUnitList,
        infrastructureList: response.data.infrastructureList,
        communicationLinkageList: response.data.communicationLinkageList,
        designationOfProductList: response.data.designationOfProductList,
        vehicleList: response.data.vehicleList,
        commentList: response.data.commentList,
        // priceTypeList: response.data.priceTypeList
        unitAssignList: response.data.unitAssignList
      })
      this.$store.dispatch('agriMarketing/changeAgriMarketingDropdown', { value: this.$i18n.locale })
      this.loadDropdownDam()
    },
    getDivisionList (divisionListAll, divisionId) {
      let divisionList = []
      if (divisionId) {
        if (divisionId.length > 0) {
          divisionId.forEach((item, key) => {
            const divisionLists = divisionListAll.filter(item1 => item1.value === parseInt(item))
            divisionLists.forEach((item1, key) => {
              divisionList.push(item1)
            })
          })
        }
      } else {
        divisionList = divisionListAll
      }
      return divisionList
    },
    getDistrictList (districtListAll, districtId) {
      let districtList = []
      if (districtId) {
        if (districtId.length > 0) {
          districtId.forEach((item, key) => {
            const districtIdLists = districtListAll.filter(item1 => item1.value === parseInt(item))
            districtIdLists.forEach((item1, key) => {
              districtList.push(item1)
            })
          })
        }
      } else {
        districtList = districtListAll
      }
      return districtList
    },
    getUpazilaList (upazilaAll, upazilaId) {
      let upazilaList = []
      if (upazilaId) {
        if (upazilaId.length > 0) {
          upazilaId.forEach((item, key) => {
            const upazilaListLists = upazilaAll.filter(item1 => item1.value === parseInt(item))
            upazilaListLists.forEach((item1, key) => {
              upazilaList.push(item1)
            })
          })
        }
      } else {
        upazilaList = upazilaAll
      }
      return upazilaList
    },
    getMarketList (marketAll, marketId) {
      let marketList = []
      if (marketId) {
        if (marketId.length > 0) {
          marketId.forEach((item, key) => {
            const marketAllLists = marketAll.filter(item1 => item1.value === parseInt(item))
            marketAllLists.forEach((item1, key) => {
              marketList.push(item1)
            })
          })
        }
      } else {
        marketList = marketAll
      }
      return marketList
    },
    storeDam (divisionList, districtList, upazilaList) {
      this.$store.commit('agriMarketing/mutateDataDam', {
        hasDropdownLoaded: true,
        divisionList: divisionList,
        districtList: districtList,
        upazilaList: upazilaList
      })
      this.$store.dispatch('agriMarketing/mutateDataDamLocale', { value: this.$i18n.locale })
    },
    async loadDropdownDam () {
      let divisionList = []
      let districtList = []
      let upazilaList = []
      const response = await RestApi.getData(agriMarketingServiceBaseUrl, 'common-data-dam', null)
      if (response.success) {
        divisionList = response.data.divisionList
        districtList = response.data.districtList
        upazilaList = response.data.upazilaList
        const activeRoleId = this.$store.state.Auth.activeRoleId
        if (activeRoleId !== 1) {
          const loggedUser = this.$store.state.agriMarketing.commonObj.loggedUserPrivilege
          divisionList = this.getDivisionList(divisionList, loggedUser.division_id)
          districtList = this.getDistrictList(districtList, loggedUser.district_id)
          upazilaList = this.getUpazilaList(upazilaList, loggedUser.upazila_id)
          this.storeDam(divisionList, districtList, upazilaList)
        } else {
          this.storeDam(divisionList, districtList, upazilaList)
        }
      }
    }
  }
}
